import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@mui/material-nextjs/v13-appRouter/index.js");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/swiper/swiper.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/swiper/modules/navigation.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/swiper/modules/autoplay.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/swiper/modules/effect-fade.css");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@fontsource/roboto/300.css");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@fontsource/roboto/400.css");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@fontsource/roboto/500.css");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/node_modules/@fontsource/roboto/700.css");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/components/AppBar/AppBar.tsx");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/components/Collaborate.tsx");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/components/Footer/FooterComponent.tsx");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/reset.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/colors-dark.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/global.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/utils.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/colors.scss");
import(/* webpackMode: "eager" */ "/opt/bitnami/apache/htdocs/apps/voh-main-next/src/styles/colors-light.scss")